
import { request as axios } from '@/util/request';
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import EditableCell from '@/component/editable-cell.vue';
import { WARE_STATUS_LIST, getHomeActivityWare, queryWare, updateWareSort } from '@/api/ware/management';
import { getSimpleCategory, queryWareCategory } from '@/api/ware/category';
import { WareData, PaginationData } from '@/api/operation/customer';
import {
  initWareList,
  addHomeWare,
  configProduct,
  getHomeWareDetail,
  getSignleHomeWare,
  initSignleHomeWare,
  editHomeWare,
  deleteWare,
  submiteditHomeWare
} from '@/api/operation/home_ware';
import CustomModal from '@/component/custom-modal.vue';
import { Validate, getValidatorMap } from '@/mixin/validator';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';
//import axios from 'axios';
interface Form {
  title: string;
  subtitle: string;
}
function initForm(): Form {
  return {
    title: '',
    subtitle: '',
  };
}

interface ModalFormData {
  ware_id: string;
  ware_name: string;
  ware_category: number[];
  status: number;
}

function initModalFormData(): ModalFormData {
  return {
    ware_id: '',
    ware_name: '',
    ware_category: [],
    status: 0,
  };
}

const WARE_STATUS = [
  {
    id: 0,
    label: '全部',
  },
  {
    id: 1,
    label: '上架中',
  },
  {
    id: 2,
    label: '已下架',
  },
];
const TITLE_MAX_LENGTH = 4;
const SUBTITLE_MAX_LENGTH = 6;
@Component({
  components: {
    EditableCell,
    CustomModal,
  },
})
export default class AddWareModule extends Mixins(Validate) {
  globalFunction=useGlobalFunction().globalFunction;
  real_total: number = 0;
  delete_ware_index: number = -1;
  is_check_all: boolean = false;
  is_loading: boolean = false;
  is_edit: boolean = false;
  add_ware: any = [];
  new_id: number = -1;
  is_show_modal: boolean = false;
  is_show_delete_modal: boolean = false;
  addShop: boolean = false;
  form_item_layout: any = {
    labelCol: { span: 2 },
    wrapperCol: { span: 22 },
  };
  more_delete: any = [];
  form: Form = initForm();
  selectedRowKeys: any = [];
  modalSelectedRowKeys: any = [];
  modalSelectedData: any = [];
  modal_form_data: ModalFormData = initModalFormData();
  table_data: any = {
    current: 0,
    detail: [],
    total: 0,
    limit: 0,
    last: 0,
  };
  ware_category: any = [];
  ware_data: PaginationData<WareData> = initWareList();
  validator_list: any = [
    {
      field: 'title',
      message: '请填写标题',
    },
    {
      field: 'subtitle',
      message: '请填写副标题',
    },
  ];
  validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);
  delete_id_list: number[] = [];
  get wareStatus() {
    return WARE_STATUS;
  }
  get titleMaxLength() {
    return TITLE_MAX_LENGTH - this.form.title.length;
  }

  get subtitleMaxLength() {
    return SUBTITLE_MAX_LENGTH - this.form.subtitle.length;
  }

  // 商品排序
  get tableData() {
    return this.table_data.detail.sort((a: any, b: any) => {
      return a.sort - b.sort;
    });
  }

  @Watch('table_data', { deep: true })
  changeTableData() {
    // this.table_data.total = this.table_data.detail.length;
    this.table_data.detail.forEach((ware: any) => {
      this.ware_category.forEach((category: any) => {
        if (ware.category.parent_id === category.id && ware.category.parent_id !== 0) {
          ware.category.parent_category = `${category.name}/`;
        }
      });
    });
  }
  async add() {
    this.is_show_modal = true;
    //  if(this.is_edit){
    //   const id_list=this.ware_data.detail.map((x: any)=>x.id);

    // }
  }
  cancelDelete() {
    this.is_show_delete_modal = false;
    this.delete_ware_index = -1;
  }
  PaginationOption(data: any) {
    return {
      showTotal: () => `共${data.total}条，每页5条`,
      total: data.total || 0,
      pageSize: data.limit || 1,
      current: data.current || 1,
    };
  }

  // 切换分页
  @changeLoading(['is_loading'])
  async handleChange(data: any,) {
    if (this.is_edit) {
      this.ware_data = (await getHomeActivityWare(data.current, +this.$route.query.id, 5 )) as any;
    } else {
      //  this.ware_data = await queryWare(data.current,{ page: 5}) as any;
     
      // this.clickSearchBtn(data.current)
      const query_params: {
      product_id?: string;
      product_name?: string;
      category_id?: number;
      is_show: any;
      page: number;
    } = {
      product_id: this.modal_form_data.ware_id,
      product_name: this.modal_form_data.ware_name,
      category_id: this.modal_form_data.ware_category[
        this.modal_form_data.ware_category.length - 1
      ],
      is_show: this.modal_form_data.status === 0 ? ('' as any) : this.modal_form_data.status,
      page: 5,
    }
    query_params.product_id == '' ? delete query_params.product_id : query_params.product_id
    query_params.product_name == '' ? delete query_params.product_name : query_params.product_name
    query_params.category_id == undefined ? delete query_params.category_id : query_params.category_id
    query_params.is_show == '' ? delete query_params.is_show : query_params.is_show

    this.ware_data = await queryWare(data.current,query_params) as any; 
    }
    this.ware_data.current = data.current;
   
  }

  @changeLoading(['is_loading'])
  async handleChangeTableData(data: any) {
    this.table_data.current = data.current;
    if (!this.is_edit) {
      this.table_data.current = data.current;
      // this.getHomeWareDetail(data.current)
      return;
    }
    this.getHomeWareDetail(data.current);
  }

  // 商品列表勾选时
  tableDataSelectChange(selectedRowKeys: any, records: any) {
    this.selectedRowKeys = selectedRowKeys;
    this.more_delete = records.map((item: any) => {
      return item.edit_id;
    })
  }

  @Watch('selectedRowKeys')
  watchCheckAllBtn() {
    if (
      this.table_data.detail.length <= 10 &&
      this.selectedRowKeys.length === this.table_data.detail.length
    ) {
      this.is_check_all = true;
    } else if (this.selectedRowKeys.length === 10) {
      this.is_check_all = true;
    } else {
      this.is_check_all = false;
    }
  }

  // 关闭商品模态窗
  @changeLoading(['is_loading'])
  async closeModal() {
    if (this.is_edit) {
      this.modalSelectedRowKeys = [];
    }

    this.is_show_modal = false;
    this.modal_form_data = initModalFormData();
    // this.modalSelectedRowKeys = this.table_data.detail.map((item: any) => item.id);
    // if(this.is_edit){
    //   this.ware_data = (await getHomeActivityWare(1, +this.$route.query.id,5)) as any;
    // }

  }

  // 全选
  checkAll() {
    if (this.is_check_all) {
      this.selectedRowKeys = this.table_data.detail.map((item: any) => item.id);
    } else {
      this.selectedRowKeys = [];
    }
  }

  // 给商品添加父级分类
  @Watch('ware_data', { deep: true })
  wareDataCategoryFormat() {
    this.addParentCategory(this.ware_data.detail);
  }
  // 给商品列表添加父级分类
  addParentCategory(ware_list: any) {
    ware_list.forEach((item: any) => {
      this.ware_category.forEach((item2: any) => {
        if (item.category.parent_id === item2.id && item.category.parent_id !== 0) {
          item.category.parent_name = `${item2.name} /`;
        }
      });
    });
  }

  // 勾选模态窗中的商品时
  modalTableSelectChange(selectedRowKeys: any, records: any) {
    this.modalSelectedRowKeys = [...selectedRowKeys];  //新添加的商品
    if (!this.is_edit) {
      const table_id = this.table_data.detail.map((item: any) => item.id);
      this.add_ware = this.add_ware.concat(records);
      // this.ware_data.detail.forEach((item: any)=>{
      //   if(table_id.indexOf(item.id)!==-1&&selectedRowKeys.indexOf(item.id)==-1){   //反选去除
      //     this.table_data.detail.splice(table_id.indexOf(item.id),1);
      //   }
      // })
    }
    // selectedRowKeys.forEach((item: any)=>{
    //   if(this.modalSelectedRowKeys.indexOf(item)==-1){
    //     this.modalSelectedRowKeys.push(item)
    //   }
    // })
    // const real=this.real_total||1
    // selectedRowKeys.forEach((x: any)=>{
    //   if(this.modalSelectedRowKeys.indexOf(x)==-1){
    //     this.ware_data.detail.forEach((item: any)=>{
    //       if(item.id==x){
    //         this.add_list.push({
    //       is_new: true,
    //       ...item,
    //       sort:real+this.add_list.length+this.delete_id_list.length
    //     })
    //       }
    //     })
    //   }
    // })
    // this.modalSelectedRowKeys = selectedRowKeys;
    // const id_list = this.modalSelectedData.map((item: any) => item.id);
    // this.ware_data.detail.forEach((item: any) => {
    //   if (this.modalSelectedRowKeys.indexOf(item.id) !== -1 && id_list.indexOf(item.id)=== -1) {
    //     this.modalSelectedData.push(item);
    //   }
    // });
  }

  // 确认添加商品
  @changeLoading(['is_loading'])
  async addWare() {
    if (this.is_edit) {
      const res: any = await editHomeWare({ activity_id: +this.$route.query.id, product_id_list: this.modalSelectedRowKeys });
      if (res.status == 200) {
        this.$message.success('添加成功')
      }
      this.modalSelectedRowKeys = [];
      await this.getHomeWareDetail(1);  //刷新商品模块详情
      this.ware_data = (await getHomeActivityWare(1, +this.$route.query.id, 5)) as any; //刷新弹出框模块
    } else {
      this.table_data.detail = [];
      const obj: any = {};
      this.add_ware = this.add_ware.reduce((item: any, next: any) => {
        if (!obj[next.id]) {
          item.push(next);
          obj[next.id] = true;
        }
        return item;
      }, []);
      let sort = 1;
      const id_list = this.add_ware.map((item: any) => item.id);
      this.modalSelectedRowKeys.forEach((item: any, index: number) => {
        if (id_list.indexOf(item) !== -1) {
          this.table_data.detail.push({ ...this.add_ware[id_list.indexOf(item)], sort: sort });
          sort++;
        }
      })
      this.table_data.total = this.table_data.detail.length;
      this.modal_form_data = initModalFormData();
      this.ware_data = await queryWare(1, { page: 5 }) as any;
      // let sort=1;
      //   this.add_ware.forEach((item: any)=>{
      //   item.sort=sort;
      //   sort++;
      // })
      // this.table_data.detail=this.table_data.detail.concat(this.add_ware);
      // this.table_data.total=this.table_data.detail.length;
      // this.table_data.limit=10;
      // console.log(this.add_ware);
    }

    this.ware_data.current = 1;
    this.is_show_modal = false;
    this.addShop = false
  }

  // 点击搜索按钮
  @changeLoading(['is_loading'])
  async  clickSearchBtn() {
    const query_params: {
      product_id?: string;
      product_name?: string;
      category_id?: number;
      is_show: any;
      page: number;
    } = {
      product_id: this.modal_form_data.ware_id,
      product_name: this.modal_form_data.ware_name,
      category_id: this.modal_form_data.ware_category[
        this.modal_form_data.ware_category.length - 1
      ],
      is_show: this.modal_form_data.status === 0 ? ('' as any) : this.modal_form_data.status,
      page: 5,
    }
  
    query_params.product_id == '' ? delete query_params.product_id : query_params.product_id
    query_params.product_name == '' ? delete query_params.product_name : query_params.product_name
    query_params.category_id == undefined ? delete query_params.category_id : query_params.category_id
    query_params.is_show == '' ? delete query_params.is_show : query_params.is_show
    this.ware_data = (await queryWare(1, query_params)) as any;   
  }

  // 点击删除按钮
  clickDeleteBtn(index: number, record: any) {
    this.new_id = record.id;
    this.is_show_delete_modal = true;
    this.delete_ware_index = index;
  }
  // 确认删除商品
  async confirmDeleteWare() {
    if (this.is_edit) {         //编辑
      if (this.delete_ware_index >= 0) {      //单独删除
        const arr: any = [];
        arr.push(this.table_data.detail[this.delete_ware_index].edit_id);  //要带edit_id
        const send_data = { product_id_list: arr, activity_id: +this.$route.query.id }
        const res: any = await deleteWare(send_data);
        if (res.status == 200)
          this.$message.success('删除成功');
      } else {    //批量删除
        const send_data = { product_id_list: this.more_delete, activity_id: +this.$route.query.id }
        const res: any = await deleteWare(send_data);
        if (res.status == 200) {
          this.$message.success('删除成功')
        }
        this.selectedRowKeys = [];
        this.more_delete = [];

      }
      await this.getHomeWareDetail(1);  //刷新商品模块详情
      this.ware_data = (await getHomeActivityWare(1, +this.$route.query.id, 5)) as any; //刷新弹出框模块
    } else {      //新增模块
      if (this.delete_ware_index >= 0) {  //单独删除
        this.modalSelectedRowKeys.splice(this.modalSelectedRowKeys.indexOf(this.new_id), 1);
        this.table_data.detail.forEach((item: any, index: number) => {
          if (item.id == this.new_id) {
            this.table_data.detail.splice(index, 1);
          }
        })
        this.table_data.total = this.table_data.detail.length;
      } else {     //批量删除
        this.selectedRowKeys.forEach((item: any, index: number) => {
          const id_list = this.table_data.detail.map((item: any) => item.id);
          this.modalSelectedRowKeys.splice(this.modalSelectedRowKeys.indexOf(item), 1);
          this.table_data.detail.splice(id_list.indexOf(item), 1);
          this.table_data.total = this.table_data.detail.length;
        })
      }
    }
    this.selectedRowKeys = [];
    this.is_show_delete_modal = false;
    this.delete_ware_index = -1;
    // if(this.table_data.detail.length==0&&this.table_data.current!==1){
    //   this.getHomeWareDetail(this.table_data.current-1);
    // }

  }

  // 编辑排序值
  async handleSortChange(index: number, value: any) {
    console.log('00000')
    this.table_data.detail[index].sort = value;
    //const res: any=await updateWareSort(this.table_data.detail[index].edit_id,value);
    const res: any = await axios.put(`/boss/operation/activity/product/sort/${this.table_data.detail[index].edit_id}`, { sort: value });
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    } else {
      this.$message.success('修改成功');
    }
  }

  // 获取单个活动详情
  @changeLoading(['is_loading'])
  async getHomeWareDetail(current: number) {
    const res: any = await getHomeWareDetail(+this.$route.query.id);
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.form.title = res.data.title;
    this.form.subtitle = res.data.subtitle;
    const res2: any = await getSignleHomeWare(+this.$route.query.id, current);
    if (res2.status !== 200) {
      this.$message.error(res2.message);
      return;
    }
    this.table_data = initSignleHomeWare(res2.data);
    this.table_data.total = res2.data.total;
    this.table_data.detail.forEach((item: any) => {
      item.banner_url_list = item.viewpager_url.split(',');
      item.sale_count = item.sales;
      item.is_new = false;
      item.is_edit = false;
      item.origin_price = item.price;
    });

    this.real_total = this.table_data.total;
    this.table_data.limit = this.table_data.detail.length > 10 ? this.table_data.detail.length : 10;
    this.table_data.detail.forEach((x: any) => {
      if (this.delete_id_list.indexOf(x.edit_id) !== -1) {
        this.table_data.detail.splice(this.delete_id_list.indexOf(x.edit_id), 1);
        this.table_data.total--;
      }
    })
    // this.modalSelectedRowKeys = this.modalSelectedRowKeys.concat(this.table_data.detail.map((item: any) => item.id));
    // this.modalSelectedData = JSON.parse(JSON.stringify(this.table_data.detail));
  }

  //提交
  @changeLoading(['is_loading'])
  async submit() {
    console.log(this.table_data.detail)
    if (!this.validateCommit()) {
      return;
    }
    if (!this.form.title) {
      this.$message.error('请输入标题');
      return;
    }
    if (!this.form.subtitle) {
      this.$message.error('请输入副标题');
      return;
    }
    if (this.table_data.detail.length <= 0) {
      this.addShop = true;
      return;
    }
    let res;
    if (this.is_edit) {
      const add = this.table_data.detail.filter((item: any) => {
        return item.is_new;
      });
      const edit = this.table_data.detail.filter((item: any) => {
        return item.is_edit;
      });
      const send_data = {
        title: this.form.title,
        subtitle: this.form.subtitle,
        add: [],
        edit: [],
        delete: [],
      };
      res = await submiteditHomeWare(+this.$route.query.id, send_data);
    } else {
      const send_data = {
        title: this.form.title,
        subtitle: this.form.subtitle,
        product: this.table_data.detail.map((item: any) => {
          return {
            product_id: item.id,
            sort: item.sort,
          };
        }),
      };
      res = await addHomeWare(send_data);
    }
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.$message.success('新增成功');
    // this.$router.go(-1);
    this.$router.push({
      path:'/operation/home-ware',
      query:{agpage: this.$route.query?.page || '1'}
    })
    this.globalFunction.getHomeWareLis()
  }

  @changeLoading(['is_loading'])
  async created() {
    this.is_edit = !!this.$route.query.id;
    // const res1=await configProduct(Number(this.$route.query.id),id_list.join(','));
    // this.modalSelectedRowKeys=Array.from(new Set(this.modalSelectedRowKeys.concat(res1.data)));
    if (this.is_edit) {
      const res = await getHomeActivityWare(1, +this.$route.query.id, 5);
      this.ware_category = await queryWareCategory();
      this.ware_data = initWareList(res as any);
      const id_list = this.ware_data.detail.map((x: any) => x.id);
      this.ware_data.current = 1;
      await this.getHomeWareDetail(1);
    } else {
      const res = await queryWare(1, { page: 5 });
      this.ware_category = await queryWareCategory();
      this.ware_data = initWareList(res as any);
      this.table_data.limit = 10;
    }
  }
}
